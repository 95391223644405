/**
 * src/css/partials/layoutmode.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-28
 */
.cmt-group {
	min-height: 64px;
	min-width: 64px;
}

.cmt-group.ui-droppable {
	outline: 1px solid orange;
}


