/**
 * app/css/components/listen.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2024-03-06
 */
.album {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2rem;

    img {
        width: 400px;
        display: block;
        box-shadow: 0 0 6rem rgba(0, 0, 0, 0.5);

    }

}
.streamings {
    text-align: center;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;

    a {
        color: #fff;
        text-decoration: underline;
    }
}
