/**
 * src/css/components/footer.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-28
 */
.main-footer {
	display: flex;
	justify-content: center;
	padding: 1.5rem 0;

	a {
		color: #fff;
		text-decoration: underline;
	}
}


