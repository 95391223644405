/**
 * src/css/partials/common.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-28
 */
*,*::before,*::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html,body {
	min-height: 100%;
}

img, video {
	max-width: 100%;
	height: auto;
	display: block;
}

.video {
	margin-left: calc(50% - 50vw);
	margin-right: calc(50% - 50vw);
	video {
		width: 75%;
		margin: 0 auto;
	}
}

@mixin visually-hidden {
        clip-path: inset(100%);
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
}

.visually-hidden {
        @include visually-hidden;
}

