/**
 * src/css/settings.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-22
 */


$black: rgba(30, 30, 30, 1);
$white: rgba(255, 255, 255, 1);
$bgColor: rgba(59, 43, 108);

$use-type-scale: true;
$vertical-unit: 1.5rem;
$modular-scale-ratio: 1.2;


:root {
	--vertical-unit: #{$vertical-unit};
	--bg-color: #{$bgColor};
	--black: #{$black};
	--white: #{$white};
	--body-font: "Fira Code";
	--headline-font: 'Edo';
	--headline-font: 'Oswald';
	--h1-font: 'Mind Explorer';
	--max-width: 600px;
	// --headline-font: 'Bebas Neue';
	// --headline-font: 'Montserrat';
	// --headline-font: 'League Spartan';
	// --headline-font: 'Rock.CO Personal use';

	--outer-margin: 1.5rem;
	--inner-margin: 1.5rem;
	--font-size: 18px;

	@media screen and (min-width: 600px) {
		--outer-margin: 2rem;
		--inner-margin: 2rem;
		--font-size: 20px;
	}

	@media screen and (min-width: 900px) {
		--outer-margin: 3rem;
		--inner-margin: 3rem;
		--font-size: 24px;
	}
}

