/**
 * src/css/partials/webfonts.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-29
 */
$firaCodeWeights: 300, regular, 500, 600, 700;
$firaCodeBasePath: '/fonts/fira-code-v21-latin';

@each $weight in $firaCodeWeights {
	@font-face {
		font-display: swap;
		font-family: 'Fira Code';
		font-style: normal;
		font-weight: #{$weight};
		src: 	url('#{$firaCodeBasePath}/fira-code-v21-latin-#{$weight}.eot');
		src: 	url('#{$firaCodeBasePath}/fira-code-v21-latin-#{$weight}.eot?#iefix') format('embedded-opentype'),
				url('#{$firaCodeBasePath}/fira-code-v21-latin-#{$weight}.woff2') format('woff2'),
				url('#{$firaCodeBasePath}/fira-code-v21-latin-#{$weight}.woff') format('woff'),
				url('#{$firaCodeBasePath}/fira-code-v21-latin-#{$weight}.ttf') format('truetype'),
				url('#{$firaCodeBasePath}/fira-code-v21-latin-#{$weight}.svg#FiraCode') format('svg');
	}
}


$oswaldWeights: 200, 300, regular, 500, 600, 700;
$oswaldBasePath: '/fonts/oswald-v49-latin';

@each $weight in $oswaldWeights {
	@font-face {
		font-display: swap;
		font-family: 'Oswald';
		font-style: normal;
		font-weight: #{$weight};
		src: url('#{$oswaldBasePath}/oswald-v49-latin-#{$weight}.eot');
		src:
			url('#{$oswaldBasePath}/oswald-v49-latin-#{$weight}.eot?#iefix') format('embedded-opentype'),
			url('#{$oswaldBasePath}/oswald-v49-latin-#{$weight}.woff2') format('woff2'),
			url('#{$oswaldBasePath}/oswald-v49-latin-#{$weight}.woff') format('woff'),
			url('#{$oswaldBasePath}/oswald-v49-latin-#{$weight}.ttf') format('truetype'),
			url('#{$oswaldBasePath}/oswald-v49-latin-#{$weight}.svg#FiraCode') format('svg');
	}
}
