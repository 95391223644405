/**
 * src/css/partials/noisy-background.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-29
 */
:root {
	--op1: rgba(255, 255, 255, 0.2);
	--op2: rgba(255, 255, 255, 0.1);
	--w1: 20vw;
	--w2: calc(100% - var(--w1));
}

.noisy-background {
	background-color: var(--noisy-bg-color);
	background-image: url('/img/noise.png'),
		linear-gradient(
			to right,
			var(--op2) 0,
			var(--op1) var(--w1),
			var(--op1) var(--w2),
			var(--op2) 100%
	);
	background-size: 100px 100px, 100% 1px;
	background-repeat: repeat, repeat-y;
}

