/**
 * src/css/components/separator.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-28
 */
.separator {
	display: flex;
	justify-content: center;
	gap: 1.5rem;
	width: 100%;

	> svg {
		flex: 0 0 2rem;
	}
}
