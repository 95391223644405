/**
 * src/css/partials/layout.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-28
 */

body {
	font-family: var(--body-font);
	color: var(--white);
	font-size: var(--font-size);
}



.wrapper {
	z-index: 1;
	position: relative;

	padding: var(--outer-margin);
	padding-bottom: 0;
	--noisy-bg-color: var(--bg-color);

}

.bg-photo {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    opacity: 0.05;
    z-index: -1;
    mix-blend-mode: hard-light;
    pointer-events: none;
}


.shape {
		content: '';
		position: absolute;
		inset: 0;
		background: rgba(255, 255, 255, 0.05);
		clip-path: polygon(0 90%, 100% 10%, 100% 90%, 0 100%);
		transition: 5000ms ease-out;
}


$fading-border: false;
.inner-wrapper {
	// border: 3px solid var(--white);
	// border-radius: 40px;
	padding: var(--inner-margin);
	padding-bottom: 4rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	@if $fading-border == false {
		border: 3px solid #fff;
		border-radius: 40px;
	}
	@else {
		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 0;
			width: 100%;
			border-style: solid;
			border-color: #fff;
		}
		&::before {
			left: 0;
			height: 35vh;
			border-radius: 40px 40px 0 0;
			border-top-width: 3px;
			border-left-width: 3px;
			border-right-width: 3px;
			border-bottom-width: 0;
		}

		&::after {
			top: 35vh;
			bottom: 0;
			border-left-width: 3px;
			border-right-width: 3px;
			border-image:
				linear-gradient(to bottom,
					rgba(255, 255, 255, 1) 0,
					rgba(255, 255, 255, 0) 50vh
				) 1 100%;
		}
	}


	// @media screen and (min-width: 900px) {
	// 	border-right: 0;
	// 	margin-right: -6rem;
    //
	// 	display: grid;
	// 	grid-template-columns: 1fr 1fr;
	// 	grid-template-rows: 150px 1fr;
	// 	gap: 3rem;
	// }
}

.main-content {
	max-width: var(--max-width);
	width: 100%;
	--stack-spacing: 4rem;
}


.stack > * + * {
	margin-top: var(--stack-spacing, 1.5rem);

}

.stack.stack--full > * {
	margin-top: var(--stack-spacing, 1.5rem);
}

.stack.stack--large {
	--stack-spacing: 8rem;
}


main {
	height: 100%;
	min-height: 100vh;
	flex: 1;
	padding: var(--vu) var(--gutter);
}


.offscreen {
	--noisy-bg-color: #000;
	overflow: hidden;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 1rem 3rem;
	z-index: 0;
	div {
		text-align: left;
		max-width: var(--max-width);
		margin: 0 auto;
	}
}
