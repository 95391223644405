/**
 * src/css/components/headline.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-28
 */
.headline {
	font-family: var(--headline-font);
	font-size: 30px;
	@media screen and (min-width: 600px) {
		font-size: 40px;
	}
	@media screen and (min-width: 900px) {
		font-size: 60px;
	}	
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	margin: 0 0 0 0;
	text-align: center;
	margin-bottom: 30px;
	color: #fff;
}

