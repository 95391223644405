/**
 * src/css/partials/index.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-28
 */
@forward "webfonts";
@forward "typography";
@forward "common";
@forward "layout";
@forward "layoutmode";
@forward "noisy-background";
