/**
 * src/css/components/figure.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-28
 */
figure {

	margin: 3rem 0;

	img {
		max-width: 100%;
		display: block;
		filter: grayscale(1);
		border: 3px solid rgba(255, 255, 255, 1.0);
	}
}

