/**
 * src/css/main.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-28
 */
/**
 * src/css/settings.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-22
 */
:root {
  --vertical-unit: 1.5rem;
  --bg-color: rgb(59, 43, 108);
  --black: rgb(30, 30, 30);
  --white: rgb(255, 255, 255);
  --body-font: "Fira Code";
  --headline-font: "Edo";
  --headline-font: "Oswald";
  --h1-font: "Mind Explorer";
  --max-width: 600px;
  --outer-margin: 1.5rem;
  --inner-margin: 1.5rem;
  --font-size: 18px;
}
@media screen and (min-width: 600px) {
  :root {
    --outer-margin: 2rem;
    --inner-margin: 2rem;
    --font-size: 20px;
  }
}
@media screen and (min-width: 900px) {
  :root {
    --outer-margin: 3rem;
    --inner-margin: 3rem;
    --font-size: 24px;
  }
}

/**
 * src/css/partials/index.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-28
 */
/**
 * src/css/partials/webfonts.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-29
 */
@font-face {
  font-display: swap;
  font-family: "Fira Code";
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/fira-code-v21-latin/fira-code-v21-latin-300.eot");
  src: url("/fonts/fira-code-v21-latin/fira-code-v21-latin-300.eot?#iefix") format("embedded-opentype"), url("/fonts/fira-code-v21-latin/fira-code-v21-latin-300.woff2") format("woff2"), url("/fonts/fira-code-v21-latin/fira-code-v21-latin-300.woff") format("woff"), url("/fonts/fira-code-v21-latin/fira-code-v21-latin-300.ttf") format("truetype"), url("/fonts/fira-code-v21-latin/fira-code-v21-latin-300.svg#FiraCode") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Fira Code";
  font-style: normal;
  font-weight: regular;
  src: url("/fonts/fira-code-v21-latin/fira-code-v21-latin-regular.eot");
  src: url("/fonts/fira-code-v21-latin/fira-code-v21-latin-regular.eot?#iefix") format("embedded-opentype"), url("/fonts/fira-code-v21-latin/fira-code-v21-latin-regular.woff2") format("woff2"), url("/fonts/fira-code-v21-latin/fira-code-v21-latin-regular.woff") format("woff"), url("/fonts/fira-code-v21-latin/fira-code-v21-latin-regular.ttf") format("truetype"), url("/fonts/fira-code-v21-latin/fira-code-v21-latin-regular.svg#FiraCode") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Fira Code";
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/fira-code-v21-latin/fira-code-v21-latin-500.eot");
  src: url("/fonts/fira-code-v21-latin/fira-code-v21-latin-500.eot?#iefix") format("embedded-opentype"), url("/fonts/fira-code-v21-latin/fira-code-v21-latin-500.woff2") format("woff2"), url("/fonts/fira-code-v21-latin/fira-code-v21-latin-500.woff") format("woff"), url("/fonts/fira-code-v21-latin/fira-code-v21-latin-500.ttf") format("truetype"), url("/fonts/fira-code-v21-latin/fira-code-v21-latin-500.svg#FiraCode") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Fira Code";
  font-style: normal;
  font-weight: 600;
  src: url("/fonts/fira-code-v21-latin/fira-code-v21-latin-600.eot");
  src: url("/fonts/fira-code-v21-latin/fira-code-v21-latin-600.eot?#iefix") format("embedded-opentype"), url("/fonts/fira-code-v21-latin/fira-code-v21-latin-600.woff2") format("woff2"), url("/fonts/fira-code-v21-latin/fira-code-v21-latin-600.woff") format("woff"), url("/fonts/fira-code-v21-latin/fira-code-v21-latin-600.ttf") format("truetype"), url("/fonts/fira-code-v21-latin/fira-code-v21-latin-600.svg#FiraCode") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Fira Code";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/fira-code-v21-latin/fira-code-v21-latin-700.eot");
  src: url("/fonts/fira-code-v21-latin/fira-code-v21-latin-700.eot?#iefix") format("embedded-opentype"), url("/fonts/fira-code-v21-latin/fira-code-v21-latin-700.woff2") format("woff2"), url("/fonts/fira-code-v21-latin/fira-code-v21-latin-700.woff") format("woff"), url("/fonts/fira-code-v21-latin/fira-code-v21-latin-700.ttf") format("truetype"), url("/fonts/fira-code-v21-latin/fira-code-v21-latin-700.svg#FiraCode") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 200;
  src: url("/fonts/oswald-v49-latin/oswald-v49-latin-200.eot");
  src: url("/fonts/oswald-v49-latin/oswald-v49-latin-200.eot?#iefix") format("embedded-opentype"), url("/fonts/oswald-v49-latin/oswald-v49-latin-200.woff2") format("woff2"), url("/fonts/oswald-v49-latin/oswald-v49-latin-200.woff") format("woff"), url("/fonts/oswald-v49-latin/oswald-v49-latin-200.ttf") format("truetype"), url("/fonts/oswald-v49-latin/oswald-v49-latin-200.svg#FiraCode") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/oswald-v49-latin/oswald-v49-latin-300.eot");
  src: url("/fonts/oswald-v49-latin/oswald-v49-latin-300.eot?#iefix") format("embedded-opentype"), url("/fonts/oswald-v49-latin/oswald-v49-latin-300.woff2") format("woff2"), url("/fonts/oswald-v49-latin/oswald-v49-latin-300.woff") format("woff"), url("/fonts/oswald-v49-latin/oswald-v49-latin-300.ttf") format("truetype"), url("/fonts/oswald-v49-latin/oswald-v49-latin-300.svg#FiraCode") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Oswald";
  font-style: normal;
  font-weight: regular;
  src: url("/fonts/oswald-v49-latin/oswald-v49-latin-regular.eot");
  src: url("/fonts/oswald-v49-latin/oswald-v49-latin-regular.eot?#iefix") format("embedded-opentype"), url("/fonts/oswald-v49-latin/oswald-v49-latin-regular.woff2") format("woff2"), url("/fonts/oswald-v49-latin/oswald-v49-latin-regular.woff") format("woff"), url("/fonts/oswald-v49-latin/oswald-v49-latin-regular.ttf") format("truetype"), url("/fonts/oswald-v49-latin/oswald-v49-latin-regular.svg#FiraCode") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/oswald-v49-latin/oswald-v49-latin-500.eot");
  src: url("/fonts/oswald-v49-latin/oswald-v49-latin-500.eot?#iefix") format("embedded-opentype"), url("/fonts/oswald-v49-latin/oswald-v49-latin-500.woff2") format("woff2"), url("/fonts/oswald-v49-latin/oswald-v49-latin-500.woff") format("woff"), url("/fonts/oswald-v49-latin/oswald-v49-latin-500.ttf") format("truetype"), url("/fonts/oswald-v49-latin/oswald-v49-latin-500.svg#FiraCode") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 600;
  src: url("/fonts/oswald-v49-latin/oswald-v49-latin-600.eot");
  src: url("/fonts/oswald-v49-latin/oswald-v49-latin-600.eot?#iefix") format("embedded-opentype"), url("/fonts/oswald-v49-latin/oswald-v49-latin-600.woff2") format("woff2"), url("/fonts/oswald-v49-latin/oswald-v49-latin-600.woff") format("woff"), url("/fonts/oswald-v49-latin/oswald-v49-latin-600.ttf") format("truetype"), url("/fonts/oswald-v49-latin/oswald-v49-latin-600.svg#FiraCode") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/oswald-v49-latin/oswald-v49-latin-700.eot");
  src: url("/fonts/oswald-v49-latin/oswald-v49-latin-700.eot?#iefix") format("embedded-opentype"), url("/fonts/oswald-v49-latin/oswald-v49-latin-700.woff2") format("woff2"), url("/fonts/oswald-v49-latin/oswald-v49-latin-700.woff") format("woff"), url("/fonts/oswald-v49-latin/oswald-v49-latin-700.ttf") format("truetype"), url("/fonts/oswald-v49-latin/oswald-v49-latin-700.svg#FiraCode") format("svg");
}
/**
 * src/css/partials/typography.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-28
 */
.body-text {
  color: #fff;
  text-align: center;
}

/**
 * src/css/partials/common.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-28
 */
*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  min-height: 100%;
}

img, video {
  max-width: 100%;
  height: auto;
  display: block;
}

.video {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}
.video video {
  width: 75%;
  margin: 0 auto;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/**
 * src/css/partials/layout.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-28
 */
body {
  font-family: var(--body-font);
  color: var(--white);
  font-size: var(--font-size);
}

.wrapper {
  z-index: 1;
  position: relative;
  padding: var(--outer-margin);
  padding-bottom: 0;
  --noisy-bg-color: var(--bg-color);
}

.bg-photo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  opacity: 0.05;
  z-index: -1;
  mix-blend-mode: hard-light;
  pointer-events: none;
}

.shape {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, 0.05);
  clip-path: polygon(0 90%, 100% 10%, 100% 90%, 0 100%);
  transition: 5000ms ease-out;
}

.inner-wrapper {
  padding: var(--inner-margin);
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border: 3px solid #fff;
  border-radius: 40px;
}

.main-content {
  max-width: var(--max-width);
  width: 100%;
  --stack-spacing: 4rem;
}

.stack > * + * {
  margin-top: var(--stack-spacing, 1.5rem);
}

.stack.stack--full > * {
  margin-top: var(--stack-spacing, 1.5rem);
}

.stack.stack--large {
  --stack-spacing: 8rem;
}

main {
  height: 100%;
  min-height: 100vh;
  flex: 1;
  padding: var(--vu) var(--gutter);
}

.offscreen {
  --noisy-bg-color: #000;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem 3rem;
  z-index: 0;
}
.offscreen div {
  text-align: left;
  max-width: var(--max-width);
  margin: 0 auto;
}

/**
 * src/css/partials/layoutmode.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-28
 */
.cmt-group {
  min-height: 64px;
  min-width: 64px;
}

.cmt-group.ui-droppable {
  outline: 1px solid orange;
}

/**
 * src/css/partials/noisy-background.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-29
 */
:root {
  --op1: rgba(255, 255, 255, 0.2);
  --op2: rgba(255, 255, 255, 0.1);
  --w1: 20vw;
  --w2: calc(100% - var(--w1));
}

.noisy-background {
  background-color: var(--noisy-bg-color);
  background-image: url("/img/noise.png"), linear-gradient(to right, var(--op2) 0, var(--op1) var(--w1), var(--op1) var(--w2), var(--op2) 100%);
  background-size: 100px 100px, 100% 1px;
  background-repeat: repeat, repeat-y;
}

/**
 * src/css/components/_index.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-28
 */
/**
 * src/css/components/headline.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-28
 */
.headline {
  font-family: var(--headline-font);
  font-size: 30px;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin: 0 0 0 0;
  text-align: center;
  margin-bottom: 30px;
  color: #fff;
}
@media screen and (min-width: 600px) {
  .headline {
    font-size: 40px;
  }
}
@media screen and (min-width: 900px) {
  .headline {
    font-size: 60px;
  }
}

/**
 * src/css/components/brand.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-28
 */
.brand {
  color: var(--white);
  font-size: 17vw;
  font-size: 4rem;
  line-height: 0.8;
  text-transform: uppercase;
  text-align: center;
  font-family: var(--h1-font);
  letter-spacing: 0.03em;
  text-align: center;
}
.brand .brand__image {
  width: 80vw;
  height: 80vh;
  display: grid;
  place-items: center;
}
.brand .brand__image > * {
  height: 80%;
  width: 80%;
  object-fit: contain;
}
@media screen and (min-width: 600px) {
  .brand {
    font-size: 5rem;
  }
}
.brand__text {
  line-height: 0.7;
}
.brand__text--gong {
  font-size: 2em;
}

.octopus svg {
  vector-effect: non-scaling-stroke;
}
.octopus path {
  stroke-width: 1.75 !important;
}

/**
 * src/css/components/separator.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-28
 */
.separator {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
}
.separator > svg {
  flex: 0 0 2rem;
}

/**
 * src/css/components/gigs.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-28
 */
.gigs {
  --stack-spacing: 3rem;
  list-style: none;
  text-align: center;
}
.gigs dt {
  font-weight: bold;
}

/**
 * src/css/components/social-media.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-28
 */
.social {
  list-style: none;
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  max-width: 100%;
}
.social li {
  flex: 0 1 5rem;
}
.social a {
  display: block;
  border-radius: 50%;
  border: 2px solid var(--white);
  aspect-ratio: 1/1;
  display: flex;
}
.social a:hover {
  background-color: #fff;
}
.social a:hover svg {
  color: #606060;
}
.social a svg {
  color: var(--white);
  margin: auto;
  width: 60%;
  height: 60%;
  vector-effect: non-scaling-stroke;
}
.social a path, .social a rect, .social a circle, .social a polygon {
  stroke-width: 1;
}

/**
 * src/css/components/footer.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-28
 */
.main-footer {
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;
}
.main-footer a {
  color: #fff;
  text-decoration: underline;
}

/**
 * src/css/components/figure.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-28
 */
figure {
  margin: 3rem 0;
}
figure img {
  max-width: 100%;
  display: block;
  filter: grayscale(1);
  border: 3px solid rgb(255, 255, 255);
}

/**
 * src/css/components/image-with-text.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-02-02
 */
.image-with-text img {
  max-width: 100%;
  height: auto;
  display: block;
}
.image-with-text__image {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
.image-with-text__text {
  text-align: left;
}

/**
 * src/css/components/iframe.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-09-12
 */
.iframe-wrapper {
  display: flex;
  justify-content: center;
}
.iframe-wrapper button {
  all: inherit;
  border: 2px solid currentColor;
  color: #fff;
  padding: 1.5rem;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  margin: 0;
}

/**
 * app/css/components/listen.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2024-03-06
 */
.album {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 2rem;
}
.album img {
  width: 400px;
  display: block;
  box-shadow: 0 0 6rem rgba(0, 0, 0, 0.5);
}

.streamings {
  text-align: center;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}
.streamings a {
  color: #fff;
  text-decoration: underline;
}

a[href="#legal"],
#legal-close-btn {
  width: 100%;
  text-align: center;
  background: none;
  border: none;
  color: var(--white);
  font: inherit;
  cursor: pointer;
  text-decoration: none;
}

#legal-close-btn {
  margin-top: var(--vertical-unit);
}