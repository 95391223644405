/**
 * src/css/components/social-media.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-28
 */
.social {
	list-style: none;
	display: flex;
	gap: 1.5rem;
	justify-content: center;
	max-width: 100%;

	li {
		flex: 0 1 5rem;
	}

	a {
		display: block;
		border-radius: 50%;
		border: 2px solid var(--white);
		aspect-ratio: 1/1;
		display: flex;

		&:hover {
			background-color: #fff;
			svg {
				color: #606060;
			}
		}

		svg {
			color: var(--white);
			margin: auto;
			width: 60%;
			height: 60%;
			vector-effect: non-scaling-stroke;
		}

		path, rect, circle, polygon {
			stroke-width: 1;
		}
	}
}


