/**
 * src/css/components/brand.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-28
 */
.brand {

	color: var(--white);
	font-size: 17vw;
	font-size: 4rem;
	line-height: .8;
	text-transform: uppercase;
	text-align: center;
	font-family: var(--h1-font);
	letter-spacing: 0.03em;


    .brand__image {
        width: 80vw;
        height: 80vh;
        display: grid;
        place-items: center;

        > * {
            height: 80%;
            width: 80%;
            object-fit: contain;
        }
    }



	@media screen and (min-width: 600px) {
		font-size: 5rem;
	}
	// @media screen and (min-width: 900px) {
	// 	// font-size: 4rem;
	// }
	// @media screen and (min-width: 1200px) {
	// 	font-size: 6rem;
	// }


	// grid-column: 1 / span 1;
	// grid-row: 1 / 2;
	text-align: center;
	// max-width: var(--max-width);
	// max-width: 24rem;


    &__image {

    }

	&__text {
		line-height: 0.7;
	}

	&__text--gong {
		font-size: 2em;
	}
}

.octopus {
	svg {
		vector-effect: non-scaling-stroke;
	}

	path {
		stroke-width: 1.75 !important;
	}
}

