/**
 * src/css/main.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-28
 */
@use 'settings' as *;

@forward "partials";
@forward "components";


a[href="#legal"],
#legal-close-btn {
	width: 100%;
	text-align: center;
	background: none;
	border: none;
	color: var(--white);
	font: inherit;
	cursor: pointer;
	text-decoration: none;
}

#legal-close-btn {
	margin-top: var(--vertical-unit);
}
