/**
 * src/css/components/iframe.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-09-12
 */
.iframe-wrapper {
    display: flex;
    justify-content: center;
	button {
		all: inherit;
		border: 2px solid currentColor;
		color: #fff;
		padding: 1.5rem;
		border-radius: 10px;
		cursor: pointer;
		text-align: center;
        margin: 0;
	}
}
