/**
 * src/css/components/image-with-text.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-02-02
 */
.image-with-text {

	img {
		max-width: 100%;
		height: auto;
		display: block;
	}

	&__image {
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
	}

	&__text {
		text-align: left;
	}

}
