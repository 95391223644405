/**
 * src/css/partials/typography.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-28
 */


.body-text {
	color: #fff;
	text-align: center;
}

