/**
 * src/css/components/gigs.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package gong-operator
 * @version 2023-01-28
 */
.gigs {
	--stack-spacing: 3rem;
	list-style: none;
	text-align: center;
	// font-size: 2rem;

	dt {
		font-weight: bold;
	}
}

